.footerDiv {
  bottom: 0;
  width: 100%;
  height: 200px;
  float: right;
  background-color: #1b4160;
}
.upper-section {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}
.quote {
  font-family: "Playfair Display", serif;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #b9f0f2;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
.lower-section {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0px;
  padding-bottom: 5px;
  text-align: center;
}
.footericons {
  height: 40px;
}
.connect-links {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
#one {
  display: flex;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  transition: all ease-out 1s;
}
#one:hover {
  transform: scale(1.2);
}
#two {
  display: flex;
  align-items: center;
  padding-left: 40px;
  padding-left: 40px;
  padding-right: 40px;
  transition: all ease-out 1s;
}
#two:hover {
  transform: scale(1.2);
}
#three {
  display: flex;
  align-items: center;
  padding-left: 40px;
  padding-left: 40px;
  padding-right: 40px;
  transition: all ease-out 1s;
}
#three:hover {
  transform: scale(1.2);
}
#letsConnect {
  font-size: 1em;
  padding-top: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "Gurajada", serif;
}
/*----------------------------------*/
@media only screen and (max-width: 1100px) {
  .quote {
    font-size: 1em;
  }
  .connect-links {
    margin-left: 0;
  }
  .quote {
    margin-left: 0;
  }
  #letsConnect {
    margin-left: 0;
  }
}
/*----------------------------------*/
@media (max-width: 600px) {
  .footerWords {
    display: none;
  }
  .quote {
    padding-left: 10px;
    padding-right: 10px;
  }
}
