.ProjectsDiv {
  background-color: rgb(89, 110, 148);
}
.ProjectsDiv .projectsTitle {
  padding-left: 30px;
}
.ProjectsDiv .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ProjectsDiv .content .projectDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ProjectsDiv .content .img1 {
  width: 70%;
  padding: 5px;
}
.ProjectsDiv .content .img2 {
  width: 25%;
  padding: 5px;
}
.ProjectsDiv .content .links {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
}
.ProjectsDiv .content .firstLink {
  display: flex;
  align-items: center;
}
.ProjectsDiv .content .secondLink {
  display: flex;
  align-items: center;
}
.wwwLogo {
  height: 40px;
}
.githubLogo {
  height: 40px;
}
.separator2 {
  width: 100%;
  height: 2px;
  background-color: black;
  margin: 0;
  border: 0;
}
.ProjectsDiv .content .hoverEffect {
  padding-top: 20px;
  transition: all 1s;
}
.ProjectsDiv .content .hoverEffect:hover {
  background-color: rgb(49, 45, 45);
}


@media (max-width: 800px) {
  .content .hekatesDiv {
    flex-direction: column;
  }
  .content .everfreeDiv {
    flex-direction: column;
  }
  .content .links {
    flex-direction: column;
  }
  .content .collisionCourseDiv {
    flex-direction: column;
  }
  .content .poopetratorDiv {
    flex-direction: column;
  }
  .content .img2 {
    display: none;
  }
}