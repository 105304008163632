.resumeDiv .resumeTitle {
  padding-left: 30px;
  font-family: 'Playfair Display', serif;
}
.resumeDiv .buttonDiv {
  text-align: center;
}
.resumeDiv .buttonDiv .downloadButton {
  background-color: #002f49b2;
  text-decoration: none;
  color: white;
  width: 30%;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 25px;
  transition: all 1s ease-out;
  transition: background-color 0.5s ease;
}
.resumeDiv .buttonDiv .downloadButton:hover {
  transform: scale(1.1);
}
.resumeDiv .buttonDiv .downloadButton:active {
  background-color: white; 
}
.resumeDiv .section1 {
  display: flex;
  justify-content: space-evenly;
}
.resumeDiv .section1 a {
  text-decoration: none;
  color: black;
  transition: all ease-out 1s;
}
.resumeDiv .section1 #linked:hover {
  color: purple;
  transform: scale(1.1);
}
.resumeDiv .section1 #email:hover {
  color: purple;
  transform: scale(1.1);
}
.resumeDiv .section2 {
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
}
.resumeDiv .section3 {
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  font-size: .75em;
}
.resumeDiv .section3 #location1 {
  text-align: right;
}
.resumeDiv .section4 {
  padding-left: 30px;
  padding-right: 30px;
  text-align: justify;
}
.resumeDiv .section5 {
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  font-size: .75em;
}
.resumeDiv .section5 #location2 {
  text-align: right;
}
.resumeDiv .section6 {
  padding-left: 30px;
  padding-right: 30px;
  text-align: justify;
}
.resumeDiv .section7 {
  text-align: center;
}
.resumeDiv .section8 {
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  font-size: .75em;
}
.resumeDiv .section9 {
  padding-left: 30px;
  padding-right: 30px;
  text-align: justify;
}
.resumeDiv .section10 {
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  font-size: .75em;
}
.resumeDiv .section11 {
  padding-left: 30px;
  padding-right: 30px;
  text-align: justify;
}
.resumeDiv .section12 {
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  font-size: .75em;
}
.resumeDiv .section13 {
  padding-left: 30px;
  padding-right: 30px;
  text-align: justify;
}
.bold {
  font-weight: bold;
}
@media (max-width: 800px) {
  .resumeDiv .section1 {
    flex-direction: column;
    align-items: center;
  }
}