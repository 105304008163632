/* .construction {
  text-align: center;
  padding: 50px;
} */


.homeDiv {
  display: flex;
  flex-direction: column;
  background-color: rgb(89, 110, 148);
}
.homeDiv .meetDavidDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  transition: all 1s;
}
.homeDiv .meetDavidDiv:hover {
  background-color: black;
  color: white;
}
.homeDiv .davidResumeDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  transition: all 1s;
}
.homeDiv .davidResumeDiv:hover {
  background-color: black;
  color: white;
}
.homeDiv .davidProjectDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  transition: all 1s;
}
.homeDiv .davidProjectDiv:hover {
  background-color: black;
  color: white;
}
.homeDiv .davidProjectDiv .projectPicsDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
}
.homeDiv .davidProjectDiv .projectPicsDivMobile {
  display: none;
}
.homeDiv .meetDavidDiv #davidPic {
  height: 80%;
}
.homeDiv .davidResumeDiv #resumePic {
  height: 80%;
}
.homeDiv .davidProjectDiv .projectPicsDiv .projectPics {
  height: 80%;
  padding-right: 5px;
  padding-left: 5px;
}
.separator1 {
  width: 100%;
  height: 2px;
  background-color: black;
  margin: 0;
  border: 0;
}
.homeSubHeading {
  margin: 0;
  padding-left: 40px;
  padding-right: 40px;
} */

@media (max-width: 800px) {
  .homeDiv .meetDavidDiv {
    flex-direction: column;
  }
  .homeDiv .davidResumeDiv {
    flex-direction: column;
  }
  .homeDiv .davidProjectDiv {
    flex-direction: column;
  }
  .homeDiv .davidProjectDiv .projectPicsDiv .projectPics {
    height: 250px;
  }
  .homeSubHeading {
    padding-bottom: 20px;
  }
}
@media (max-width: 500px) {
  .homeDiv .davidProjectDiv {
    height: auto;
  }
  .homeDiv .davidProjectDiv .projectPicsDiv {
    display: none;
  }
  .homeDiv .davidProjectDiv .projectPicsDivMobile {
    display: flex;
    height: 400px;
    align-items: center;
  }
  .homeDiv .davidProjectDiv .projectPicsDivMobile .projectPics {
    height: 300px;
  }
  .homeDiv .davidResumeDiv #resumePic {
    height: 300px;
  }
}
